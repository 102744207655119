import { Pipe, PipeTransform } from '@angular/core';
import { HereGeocodeHighlightPositionModel, HereGeocodeModel } from '@app/store/heremaps/models';

@Pipe({
    name: 'autosuggestAddressSecondLine',
    standalone: true,
})
export class AutosuggestAddressSecondLinePipe implements PipeTransform {
    transform(autocomplete: HereGeocodeModel): string {
        if (autocomplete.address.city) {
            const state = autocomplete.address.state ? this.decorate(autocomplete.address.state, autocomplete.highlights.address.state) : '';
            const country = this.decorate(autocomplete.address.countryName, autocomplete.highlights.address.countryName);

            return [state, country].filter((name) => name !== '').join(', ');
        }

        if (autocomplete.address.label) {
            return this.decorate(autocomplete.address.label, autocomplete.highlights.address.label);
        }
        return this.decorate(autocomplete.title, autocomplete.highlights.title);
    }

    decorate(part: string, highlights?: HereGeocodeHighlightPositionModel[]) {
        let name = part;
        if (highlights) {
            const positions = highlights.reduce(
                (prev, curr) => {
                    return [
                        ...prev,
                        {
                            at: curr.start,
                            type: 'start',
                        },
                        {
                            at: curr.end,
                            type: 'end',
                        },
                    ];
                },
                <{ at: number; type: string }[]>[],
            );

            positions.sort((a, b) => {
                return b.at - a.at;
            });

            positions.forEach((position) => {
                name = `${name.slice(0, position.at)}${position.type === 'start' ? '<span class="highlight">' : '</span>'}${name.slice(position.at)}`;
            });
            return name;
        }
        return name;
    }
}
