import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'priceFormat',
    standalone: true,
})
export class PriceFormatPipe implements PipeTransform {
    transform(value: number | '-' | null): string {
        if (value === '-' || value === null) {
            return '-';
        }

        return value.toFixed(2);
    }
}
