<div class="nav">
    <button class="tw-ml-4" (click)="onPrevMonthClick($event)">
        <app-icon icon="arrow-left" [size]="24" />
    </button>

    <div class="tw-text-center">
        <button class="label" (click)="onMonthClick($event)">
            <span>{{ 'calendar.month.' + (month$ | async) | translate }} {{ year$ | async }}</span>
        </button>
    </div>

    <button class="tw-mr-4" (click)="onNextMonthClick($event)">
        <app-icon icon="arrow-right" [size]="24" />
    </button>
</div>

<div class="legend">
    <div translate>calendar.weekday.letter.1</div>
    <div translate>calendar.weekday.letter.2</div>
    <div translate>calendar.weekday.letter.3</div>
    <div translate>calendar.weekday.letter.4</div>
    <div translate>calendar.weekday.letter.5</div>
    <div translate>calendar.weekday.letter.6</div>
    <div translate>calendar.weekday.letter.0</div>
</div>

<div class="days">
    @for (day of dates$ | async; track day.date) {
        <button
            (click)="onSelectDate($event, day.value)"
            [ngClass]="{
                today: day.isToday,
                'current-month': day.currentMonth,
                selected: day.dateString === value,
                disabled: day.blocked,
            }"
            [disabled]="day.blocked"
        >
            {{ day.date | date: 'd' }}
        </button>
    }
</div>
