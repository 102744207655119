import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { Grow } from '../../animations';

@Component({
    selector: 'app-form-checkbox',
    styleUrls: ['./form-checkbox.component.scss'],
    templateUrl: './form-checkbox.component.html',
    standalone: true,
    animations: [Grow],
    imports: [CommonModule, ReactiveFormsModule, FormErrorsComponent],
})
export class FormCheckboxComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() label?: string;
    @Input() scrollToError = true;

    fc!: FormControl<boolean>;

    ngOnInit() {
        this.fc = <FormControl<boolean>>(<FormGroup>this.controlContainer.control).get(this.controlName);
    }
}
