import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Errors } from '../enums/validation-error-types.enum';

import XRegExp from 'xregexp';

export const StreetValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';
    const pattern = "^[\\p{L}\\p{N}\\s\\-\\/']+$";
    if (value !== '' && value.trim() !== '' && !XRegExp.test(value, XRegExp(pattern))) {
        return { [Errors.WORDS_AND_NUMBERS]: true };
    }
    if (value.trim() === '' && value !== '') {
        return { [Errors.TEXT]: true };
    }
    return null;
};
