import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class CountriesService {
    private translateService = inject(TranslateService);

    readonly countries = [
        {
            label: this.translateService.instant('country.POL'),
            value: 'PL',
        },
        {
            label: this.translateService.instant('country.DEU'),
            value: 'DE',
        },
        {
            label: this.translateService.instant('country.NLD'),
            value: 'NL',
        },
        {
            label: this.translateService.instant('country.BEL'),
            value: 'BE',
        },
        {
            label: this.translateService.instant('country.UKR'),
            value: 'UA',
        },
    ];
}
