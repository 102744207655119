import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-form-errors',
    styleUrls: ['./form-errors.component.scss'],
    templateUrl: './form-errors.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule],
})
export class FormErrorsComponent {
    @Input({ required: true }) errors!: ValidationErrors;
}
