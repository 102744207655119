import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, IconComponent } from '@app/v2/shared/components';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-number',
    templateUrl: './form-number.component.html',
    styleUrls: ['./form-number.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconComponent, ButtonComponent],
})
export class FormNumberComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() label?: string;
    @Input() scrollToError = true;

    @Input() min?: number;
    @Input() max?: number;

    fc!: FormControl<number>;

    ngOnInit() {
        this.fc = <FormControl<number>>(<FormGroup>this.controlContainer.control).get(this.controlName);
    }

    onClickDecrease() {
        if (this.min !== undefined && this.min < this.fc.getRawValue()) {
            this.fc.patchValue(this.fc.getRawValue() - 1);
        }
    }

    onClickIncrease() {
        if (this.max !== undefined && this.max > this.fc.getRawValue()) {
            this.fc.patchValue(this.fc.getRawValue() + 1);
        }
    }
}
