import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/v2/shared/components';
import { PickerService } from '../../services/picker.service';
import { Time } from '@app/v2/shared/classes';

@Component({
    selector: 'app-picker-months',
    templateUrl: './picker-months.component.html',
    styleUrls: ['./picker-months.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerMonthsComponent {
    private readonly pickerService = inject(PickerService);

    @Input() startDate!: string;
    @Input() endDate!: string;

    readonly year$ = this.pickerService.year$;
    readonly month$ = this.pickerService.month$;

    readonly months = <[number, string][]>[
        [0, 'calendar.month.0'],
        [1, 'calendar.month.1'],
        [2, 'calendar.month.2'],
        [3, 'calendar.month.3'],
        [4, 'calendar.month.4'],
        [5, 'calendar.month.5'],
        [6, 'calendar.month.6'],
        [7, 'calendar.month.7'],
        [8, 'calendar.month.8'],
        [9, 'calendar.month.9'],
        [10, 'calendar.month.10'],
        [11, 'calendar.month.11'],
    ];

    onNextYearClick(event: MouseEvent) {
        event.preventDefault();
        this.year$.next(this.year$.value + 1);
    }

    onPrevYearClick(event: MouseEvent) {
        event.preventDefault();
        this.year$.next(this.year$.value - 1);
    }

    onSelectMonth(event: MouseEvent, value: number) {
        event.preventDefault();
        this.month$.next(value);
        this.pickerService.view$.next('days');
    }

    checkDisabled(month: number) {
        const startYear = Time.getYear(this.startDate);
        const startMonth = <number>Time.getMonth(this.startDate);
        const endYear = Time.getYear(this.endDate);
        const endMonth = <number>Time.getMonth(this.endDate);

        if (this.year$.getValue() < startYear) {
            return true;
        }
        if (this.year$.getValue() === startYear && month < startMonth) {
            return true;
        }

        if (this.year$.getValue() > endYear) {
            return true;
        }
        if (this.year$.getValue() === endYear && month > endMonth) {
            return true;
        }

        return false;
    }
}
