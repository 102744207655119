import { Errors as CorelabsErrors } from '@corelabs/angular-forms';

enum CustomErrors {
    INVALID_ADDRESS = 'invalid-address',
    INVALID_STREET = 'invalid-street',
    INVALID_STREET_NUMBER = 'invalid-number',
    INVALID_DEPART_DATE = 'invalid-depart-date',
    INVALID_RETURN_DATE = 'invalid-return-date',
    INVALID_LOOKUP_ADDRESS = 'invalid-lookup-id',
}

export const Errors = { ...CorelabsErrors, ...CustomErrors };
export type Errors = typeof Errors;
