import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { fromEvent, map, debounceTime, distinctUntilChanged, startWith, shareReplay } from 'rxjs';

const SM = 639;
const MD = 767;
const XL = 1280;
interface ScrollTo {
    top?: number | HTMLElement;
    marginTop?: number;
    marginTopMobile?: number;
    skipLock?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class WindowService {
    readonly document = inject(DOCUMENT);

    readonly sm$ = fromEvent(window, 'resize').pipe(
        debounceTime(10),
        map((data) => (<Window>data.target).innerWidth),
        startWith(window.innerWidth),
        map((width) => width <= SM),
        distinctUntilChanged(),
        shareReplay(1),
    );

    readonly md$ = fromEvent(window, 'resize').pipe(
        debounceTime(10),
        map((data) => (<Window>data.target).innerWidth),
        startWith(window.innerWidth),
        map((width) => width <= MD),
        distinctUntilChanged(),
        shareReplay(1),
    );

    readonly xl$ = fromEvent(window, 'resize').pipe(
        debounceTime(10),
        map((data) => (<Window>data.target).innerWidth),
        startWith(window.innerWidth),
        map((width) => width <= XL),
        distinctUntilChanged(),
        shareReplay(1),
    );

    get scrollbarWidth() {
        return Math.abs(window.innerWidth - this.document.documentElement.clientWidth);
    }

    scrollTo(options?: ScrollTo) {
        if (this.document.body.classList.contains('lock') === false || options?.skipLock) {
            if (typeof options?.top === 'number') {
                window.scrollTo({ top: options.top - (options?.marginTop ?? 0), behavior: 'smooth' });
            } else if (options?.top instanceof HTMLElement) {
                const margin = window.innerWidth <= MD ? (options?.marginTopMobile ?? 0) : (options?.marginTop ?? 0);
                const top = options.top.getBoundingClientRect().top + window.scrollY - margin;
                window.scrollTo({ top, behavior: 'smooth' });
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }
}
