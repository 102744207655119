import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { Grow } from '../../animations';
import { IconComponent } from '../../components/icon/icon.component';
import { map, Observable, startWith } from 'rxjs';
import { FormPhonePrefixListComponent } from './form-list/form-list.component';
import { FocusService } from './service/focus.service';
import { environment } from '@env/environment';

@Component({
    selector: 'app-form-phone-prefix',
    styleUrls: ['./form-phone-prefix.component.scss'],
    templateUrl: './form-phone-prefix.component.html',
    standalone: true,
    animations: [Grow],
    providers: [FocusService],
    imports: [CommonModule, ReactiveFormsModule, FormErrorsComponent, IconComponent, FormPhonePrefixListComponent],
})
export class FormPhonePrefixComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);
    private readonly focusService = inject(FocusService);
    private readonly renderer2 = inject(Renderer2);

    @ViewChild('focusEl') focusEl!: ElementRef<HTMLDivElement>;
    @ViewChild('blurEl') blurEl!: ElementRef<HTMLDivElement>;

    @Input({ required: true }) controlName!: string;
    @Input() label?: string;
    @Input() scrollToError = true;

    fc!: FormControl<string>;

    readonly focus$ = this.focusService.focus$;

    value$!: Observable<string>;
    country$!: Observable<string>;

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);
        this.value$ = this.fc.valueChanges.pipe(startWith(this.fc.getRawValue()));
        this.country$ = this.value$.pipe(map((value) => environment.forms.phone.prefixes.items.find((item) => item.code === value)?.country ?? 'default'));
    }

    onFocusIn() {
        if (this.fc.enabled) {
            this.focusService._focus$.next(true);
            this.renderer2.addClass(this.focusEl.nativeElement, 'active');
        }
    }

    onFocusOut() {
        this.focusService._focus$.next(false);
        this.renderer2.removeClass(this.focusEl.nativeElement, 'active');
        this.fc.markAsTouched();
    }

    onSelectValueEvent(value: string) {
        this.fc.patchValue(value);
        this.blurEl.nativeElement.focus();
    }
}
