<div #focusEl (focusin)="onFocusIn()" (focusout)="onFocusOut()" tabindex="0">
    <div class="field">
        <label>{{ label }}</label>
        <input type="hidden" [formControl]="fc" />
        <input #input [placeholder]="placeholder" [value]="fc.getRawValue() | dateFormat: timeType" readonly />
        <app-icon icon="calendar" [size]="24" />
    </div>
    @if (focus$ | async) {
        <app-form-calendar-picker @Grow [startDate]="startDate ?? '1900/01/01'" [endDate]="endDate ?? '2200/12/31'" [controlName]="controlName" />
    }
</div>

@if ((disabled$ | async) === false && fc.touched && fc.invalid && fc.errors !== null) {
    <div @Grow>
        <app-form-errors class="tw-pt-2 tw-truncate" [errors]="fc.errors" />
    </div>
}
