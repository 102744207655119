import { Directive, ElementRef, inject, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription, tap } from 'rxjs';
import { WindowService } from '@app/v2/shared/services';

@Directive({
    selector: '[appScrollToError]',
    standalone: true,
})
export class ScrollToErrorDirective implements OnInit, OnDestroy {
    private readonly elementRef: ElementRef<HTMLFormElement> = inject(ElementRef);
    private readonly windowService = inject(WindowService);

    private readonly subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.subscriptions$.add(
            fromEvent(this.elementRef.nativeElement, 'submit')
                .pipe(tap(() => this.scrollToElement()))
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    private scrollToElement() {
        const firstInvalidControl: HTMLElement | null = this.elementRef.nativeElement.querySelector('[control-error="true"]');
        if (firstInvalidControl) {
            this.windowService.scrollTo({ top: firstInvalidControl, marginTop: 180, marginTopMobile: 140 });
        }
    }
}
