import { trigger, transition, animate, style } from '@angular/animations';

export const SlideRight = trigger('SlideRight', [
    transition(':enter', [
        style({
            opacity: 0,
        }),
        animate(
            '0.4s cubic-bezier(0.5, 0, 0, 1)',
            style({
                opacity: 1,
            }),
        ),
    ]),
    transition(':leave', [
        animate(
            '0.4s cubic-bezier(0.5, 0, 0, 1)',
            style({
                opacity: 0,
            }),
        ),
    ]),
]);
