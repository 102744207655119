import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-form-radio',
    styleUrls: ['./form-radio.component.scss'],
    templateUrl: './form-radio.component.html',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
})
export class FormRadioComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input({ required: true }) value!: string;
    @Input() label?: string;
    @Input() scrollToError = true;

    fc!: FormControl<string>;

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);
    }
}
