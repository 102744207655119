<div #box class="box" tabindex="-1">
    <button class="btn-close" (click)="onCloseClick($event)">
        <app-icon icon="x-mark" [size]="24" />
    </button>

    @switch (view$ | async) {
        @case ('days') {
            <app-picker-days [value]="fc.getRawValue()" [startDate]="startDate" [endDate]="endDate" />
        }
        @case ('months') {
            <app-picker-months [startDate]="startDate" [endDate]="endDate" />
        }
    }

    <div class="tw-text-center">
        <button class="btn-clear" (click)="onClearClick($event)">
            <span translate>btn.delete</span>
            <app-icon icon="bin" [size]="24" />
        </button>
    </div>
</div>
