import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { Grow } from '../../animations';
import { IconComponent } from '../../components/icon/icon.component';

@Component({
    selector: 'app-form-textarea',
    styleUrls: ['./form-textarea.component.scss'],
    templateUrl: './form-textarea.component.html',
    standalone: true,
    animations: [Grow],
    imports: [CommonModule, ReactiveFormsModule, FormErrorsComponent, IconComponent],
})
export class FormTextareComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() label?: string;
    @Input() scrollToError = true;

    fc!: FormControl<string>;

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);
    }
}
