<div class="tw-flex" [attr.control-error]="fc.invalid && scrollToError">
    <label
        class="checkbox"
        [ngClass]="{
            disabled: fc.disabled,
            checked: fc.getRawValue(),
        }"
    >
        <input type="checkbox" [formControl]="fc" />

        @if (label) {
            <div class="label">{{ label }}</div>
        } @else {
            <div class="label">
                <ng-content></ng-content>
            </div>
        }
    </label>
</div>

@if (fc.touched && fc.invalid && fc.errors !== null) {
    <div @Grow>
        <app-form-errors class="tw-pt-2" [errors]="fc.errors" />
    </div>
}
