import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Injectable()
export class FocusService {
    readonly _focus$ = new BehaviorSubject(false);
    readonly focus$ = this._focus$.pipe(debounceTime(1), distinctUntilChanged());

    readonly close$ = new Subject<null>();
    readonly selectValue$ = new Subject<string>();
}
