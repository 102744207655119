import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '../../components';

@Component({
    selector: 'app-form-switch',
    styleUrls: ['./form-switch.component.scss'],
    templateUrl: './form-switch.component.html',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, IconComponent],
})
export class FormSwitchComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() label?: string;
    @Input() scrollToError = true;

    fc!: FormControl<boolean>;

    ngOnInit() {
        this.fc = <FormControl<boolean>>(<FormGroup>this.controlContainer.control).get(this.controlName);
    }
}
