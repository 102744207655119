<label
    class="radio"
    [ngClass]="{
        disabled: fc.disabled,
        checked: fc.getRawValue() === value,
    }"
    [attr.control-error]="fc.invalid && scrollToError"
>
    <input type="radio" [value]="value" [formControl]="fc" />
    <div class="label" *ngIf="label">{{ label }}</div>
</label>
