<div class="tw-flex" [attr.control-error]="fc.invalid && scrollToError">
    <label
        class="switch"
        [ngClass]="{
            disabled: fc.disabled,
            checked: fc.getRawValue(),
        }"
    >
        <input type="checkbox" [formControl]="fc" />
    </label>
    <div class="label" *ngIf="label">{{ label }}</div>
</div>
