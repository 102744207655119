<div class="nav">
    <button class="tw-ml-4" (click)="onPrevYearClick($event)">
        <app-icon icon="arrow-left" [size]="24" />
    </button>

    <div class="tw-text-center">
        <button class="label" disabled>
            <span>{{ year$ | async }}</span>
        </button>
    </div>

    <button class="tw-mr-4" (click)="onNextYearClick($event)">
        <app-icon icon="arrow-right" [size]="24" />
    </button>
</div>

<div class="months">
    @for (month of months; track month[0]) {
        <button type="button" (click)="onSelectMonth($event, month[0])" [disabled]="checkDisabled(month[0])">
            {{ month[1] | translate }}
        </button>
    }
</div>
