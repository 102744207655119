import { inject, Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CurrencyService } from '@app/currency/services/currency.service';
import { TransitPrice } from '@app/store/transits/models';
import { OptionPrice } from '@app/store/booking-options/models';
import { Passengers } from './models/passengers.model';

@Pipe({
    name: 'price',
    standalone: true,
})
export class PricePipe implements PipeTransform {
    private currencyService = inject(CurrencyService);

    transform(value?: TransitPrice[] | OptionPrice[], passengers?: Passengers): Observable<number | '-'> {
        return this.currencyService.currency$.pipe(
            map((currency) => {
                const price = value?.find((item) => item.currency === currency);
                if (price !== undefined) {
                    if (passengers) {
                        return price.value * passengers.adults;
                    }
                    return price.value;
                }
                return '-';
            }),
        );
    }
}
