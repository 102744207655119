import { inject, Pipe, PipeTransform } from '@angular/core';
import { Time } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateFormat',
    standalone: true,
    pure: false,
})
export class DateFormatPipe implements PipeTransform {
    private translateService = inject(TranslateService);

    transform(value: string | null, type?: 'digital' | 'day-month' | 'day-mon-year'): string {
        if (value !== null && Time.stringIsDate(value)) {
            switch (type) {
                case 'digital': {
                    const [year, month, date] = value.split('-').join('/').split('/');

                    return `${date}.${month}.${year}`;
                }
                case 'day-month': {
                    const date = Time.stringToDate(value);
                    const month = <string>this.translateService.instant(`calendar.month.${date.getMonth()}`);

                    return `${date.getDate()} ${month}`;
                }
                case 'day-mon-year': {
                    const date = Time.stringToDate(value);
                    const month = <string>this.translateService.instant(`calendar.month.short.${date.getMonth()}`);
                    const year = date.getFullYear();

                    return `${date.getDate()} ${month} ${year}`;
                }
                default: {
                    const date = Time.stringToDate(value);
                    const weekday = <string>this.translateService.instant(`calendar.weekday.letters.${date.getDay()}`);
                    const month = <string>this.translateService.instant(`calendar.month.short.${date.getMonth()}`);

                    return `${weekday}, ${date.getDate()} ${month}`;
                }
            }
        }

        return '';
    }
}
