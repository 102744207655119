import { Pipe, PipeTransform } from '@angular/core';
import { HereGeocodeHighlightPositionModel, HereGeocodeModel } from '@app/store/heremaps/models';

@Pipe({
    name: 'autosuggestAddressFirstLine',
    standalone: true,
})
export class AutosuggestAddressFirstLinePipe implements PipeTransform {
    transform(autocomplete: HereGeocodeModel): string {
        if (autocomplete.address.city) {
            const streetWithNumber = `${autocomplete.address.street ? this.decorate(autocomplete.address.street, autocomplete.highlights.address.street) : ''}${
                autocomplete?.address.houseNumber ? ' ' + this.decorate(autocomplete.address.houseNumber, autocomplete.highlights.address.houseNumber) : ''
            }`;

            const city = `${
                autocomplete.address.district && autocomplete.address.district !== autocomplete.address.city
                    ? this.decorate(autocomplete.address.district, autocomplete.highlights.address.district) + ', '
                    : ''
            }${autocomplete.address.city ? this.decorate(autocomplete.address.city, autocomplete.highlights.address.city) : ''}`;

            return `${city}${streetWithNumber ? ', ' + streetWithNumber : ''}`;
        }

        if (autocomplete.address.label) {
            return this.decorate(autocomplete.address.label, autocomplete.highlights.address.label);
        }

        return this.decorate(autocomplete.title, autocomplete.highlights.title);
    }

    decorate(part: string, highlights?: HereGeocodeHighlightPositionModel[]) {
        let name = part;
        if (highlights) {
            const positions = highlights.reduce(
                (prev, curr) => {
                    return [
                        ...prev,
                        {
                            at: curr.start,
                            type: 'start',
                        },
                        {
                            at: curr.end,
                            type: 'end',
                        },
                    ];
                },
                <{ at: number; type: string }[]>[],
            );

            positions.sort((a, b) => {
                return b.at - a.at;
            });

            positions.forEach((position) => {
                name = `${name.slice(0, position.at)}${position.type === 'start' ? '<span class="highlight">' : '</span>'}${name.slice(position.at)}`;
            });
            return name;
        }
        return name;
    }
}
