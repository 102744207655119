import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';

@Component({
    selector: 'app-form-list',
    styleUrls: ['./form-list.component.scss'],
    templateUrl: './form-list.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class FormPhonePrefixListComponent {
    @Input() value!: string;
    @Output() selectValueEvent = new EventEmitter<string>();

    readonly items = environment.forms.phone.prefixes.items;
}
