import { AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from '@env/environment';

export const RequiredPhoneValidator = (control: AbstractControl): ValidationErrors | null => {
    const v = <string>control.value;

    if (!v) {
        return { required: true };
    }

    const prefixes = environment.forms.phone.prefixes.items.map((item) => item.code);
    const found = prefixes.find((prefix) => prefix.startsWith(v));

    if (found && v === found) {
        return { required: true };
    }

    return null;
};
