import { AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from '@env/environment';

import XRegExp from 'xregexp';

export const PhoneValidator = (control: AbstractControl<string>): ValidationErrors | null => {
    const v = control.value;
    const pattern = /^[0-9]{0,20}$/;
    const prefixes = environment.forms.phone.prefixes.items.map((item) => item.code);
    const found = prefixes.find((prefix) => prefix.startsWith(v));

    if (!found) {
        if (!XRegExp.test(v, XRegExp(pattern)) || v.trim() !== v) {
            return { invalidPhoneNumber: true };
        }
    }

    return null;
};
