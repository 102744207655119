<div class="tw-relative" [attr.control-error]="fc.invalid && scrollToError">
    <textarea type="text" placeholder=" " [formControl]="fc" tabindex="1"></textarea>
    <div class="label" *ngIf="label">
        <span>{{ label }}</span>
    </div>
    <app-icon icon="cricle-mark" color="primary-700" [size]="20" />
</div>
@if (fc.touched && fc.invalid && fc.errors !== null) {
    <div @Grow>
        <app-form-errors class="tw-pt-2" [errors]="fc.errors" />
    </div>
}
