import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Item } from '../models/item';

@Component({
    selector: 'app-form-list',
    styleUrls: ['./form-list.component.scss'],
    templateUrl: './form-list.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class FormDropdownListComponent {
    @Input({ required: true }) value!: string | number;
    @Input({ required: true }) items!: Item[];
    @Output() selectValueEvent = new EventEmitter<string | number>();
}
